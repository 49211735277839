// eslint-disable-next-line import/no-unresolved
import initCelBrowser, { evaluateCelScript } from '@unthread-io/cel-browser';
import _ from 'lodash';

const wasmUrl = new URL(`/assets/wasm/cel_bg.wasm`, import.meta.url);

let wasm;

/**
 * Initialize the CEL browser
 * This is safe to call multiple times, it will only initialize once
 * Returns a promise that resolves when initialization is complete
 */
export async function init(reload: boolean = false) {
  if (wasm && !reload) {
    return wasm;
  }

  try {
    wasm = await initCelBrowser({ module_or_path: wasmUrl });
  } catch (err) {
    console.error('Failed to initialize CEL browser:', err);
  }

  return wasm;
}

export const CONDITIONS_TYPES = {
  SIMPLE: 'simple',
  ADVANCED: 'advanced',
} as const;

export function simpleConditionsToAdvanced(query: any): string {
  if ('leftValue' in query || 'leftValueFrom' in query) {
    const leftValue = ('leftValue' in query ? JSON.stringify(query.leftValue) : query.leftValueFrom) ?? null;
    const rightValue = ('rightValue' in query ? JSON.stringify(query.rightValue) : query.rightValueFrom) ?? null;

    switch (query.operator) {
      case 'equals':
        return `${leftValue} == ${rightValue}`;
      case 'notEquals':
        return `${leftValue} != ${rightValue}`;
      case 'greaterThan':
        return `${leftValue} > ${rightValue}`;
      case 'lessThan':
        return `${leftValue} < ${rightValue}`;
      case 'greaterThanOrEquals':
        return `${leftValue} >= ${rightValue}`;
      case 'lessThanOrEquals':
        return `${leftValue} <= ${rightValue}`;
      case 'in':
        return `${leftValue} in ${rightValue}`;
      case 'notIn':
        return `!(${leftValue} in ${rightValue})`;
      case 'contains':
        return `${rightValue} in ${leftValue}`;
      case 'notContains':
        return `!(${rightValue} in ${leftValue})`;
      case 'isNull':
        return `${leftValue} == null`;
      case 'isNotNull':
        return `${leftValue} != null`;
    }
  } else if ('and' in query && query.and?.length > 0) {
    return `(${query.and.map((f) => simpleConditionsToAdvanced(f)).join(' && ')})`;
  } else if ('or' in query && query.or?.length > 0) {
    return `(${query.or.map((f) => simpleConditionsToAdvanced(f)).join(' || ')})`;
  }

  return 'true';
}

export function evaluateConditions(input: any, conditions?: any): boolean {
  if (!conditions) {
    return true;
  }

  if (!wasm) {
    console.warn('CEL evaluation called before initialization completed - returning default "true"');
    return true;
  }

  const advancedConditions =
    conditions.type === CONDITIONS_TYPES.ADVANCED ? conditions.advanced : simpleConditionsToAdvanced(conditions.simple);

  if (!advancedConditions.trim()) {
    return true;
  }

  try {
    return Boolean(evaluateCelScript(advancedConditions, input));
  } catch (e) {
    console.error('Failed to evaluate conditions', e);

    return false;
  }
}

export function extractTicketId(inputString: string): string | null {
  // used to extract ticket id from a string of form: "conversation.ticketTypeFields['ticket-field-id-as-a-string']"
  // rename this if you can find a more specific definition please
  const match = inputString.match(/\[\'([^\']+)\'\]/);
  if (match && match[1]) {
    return match[1];
  }
  return null;
}
